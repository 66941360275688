import { apiQueryRate_USD_KRW } from '@/resource'
import rounding from '@/util/rounding'

export default {
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency,
            })
          )
        )
      } else if (this.accountCurrency !== 'USC' && this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        )
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        )
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' })))
      } else {
        callback()
      }
    }
    return {
      koreaForm: {
        accountNumber: '',
        amount: '',
        notes: '',
      },
      koreaRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur',
          },
        ],
      },
      rate: 0.0,
      convertCurrency: false,
    }
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.koreaForm.amount, 2)
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2)
    },
  },
  mounted() {
    this.queryRate()
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.koreaForm.accountNumber = accountNumber
    },
    queryRate() {
      if (this.convertCurrency)
        apiQueryRate_USD_KRW()
          .then(resp => {
            if (resp.data.code == 0) this.rate = resp.data.data
          })
          .catch(err => {
            this.errorMessage(
              this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'KRW' })
            )
          })
    },
  },
}
